
/* ----------------------------------------------------------
 iPad対応
---------------------------------------------------------- */

$(function(){
  if (window.ontouchstart === null){
    // オブザーバインスタンスを作成
    var observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        mutation.addedNodes.forEach(function (node) {
          if (node.nodeType !== 1) {
            return;
          }
          if ($(node).is('a')) {
            $(node).addClass('touch');
          }
          $(node).find('a').addClass('touch');
        });
      });    
    });
    // オブザーバの設定
    var config = {childList: true, subtree: true};
    // 対象ノードとオブザーバの設定を渡す
    observer.observe(document.body, config);
  }

  $(document.body).on({
    'touchstart': function () {
      $(this).css({'opacity':'0.6'})
    },
    'touchend': function () {
      $(this).css({'opacity':'1.0'})
    }
  }, ".btn-cmn-09.touch,.btn-cmn-02.touch,.link.touch,.btn-cmn-03.touch");

  $(document.body).on({
    'touchstart': function () {
      $(this).addClass('hover');
    },
    'touchend': function () {
      $(this).removeClass('hover');
    }
  }, ".btn");
});

